.image-preview-section {
  margin-top: 24px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f3f3f3;
  border-radius: 4px;
  border: 1px solid var(--gray-05, #e8e8e8);
  min-height: 220px;
}

.upload-hts-images label {
  font-weight: bold;
  font-size: 18px;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  text-transform: none;
  border-radius: 30px;
}

.image-preview-container {
  display: flex;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 7px;
  margin-bottom: 7px;
}
