.dashboard-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.profile-main-content {
  padding: 0 7px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  padding-bottom: 24px;
}

.left-menu > .custom-list {
  display: flex;
  /* font-weight: 600; */
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.nav-item-disabled {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.5;
  color: grey;
}

.custom-list button {
  width: 100%;
  padding: 10px 6px;
}

.top-nav {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.profile-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0 !important;
  padding-bottom: 0 !important;
}

.unlading-logo-dashboard {
  display: flex;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-left: 0 !important;
  justify-content: center;
  align-items: center;
  margin-top: -25px;
  margin-bottom: -40px;
}

.unlading-logo-dashboard img {
  max-width: 100%;
  height: auto;
}

.right-header {
  display: flex;
  margin-right: 12px;
}

.left-header {
  display: flex;
  margin-left: 12px;
}

.left-header .profile-icon {
  width: 35px;
}

.custom-list {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.custom-list button {
  justify-content: flex-start;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.5s ease;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: nowrap;
}

.custom-list .muiSvgIcon-root {
  font-size: 24px !important;
}

.custom-list.active button {
  background: #409c44;
  color: white;
  font-weight: 600;
}

.free-plan {
  margin: -20px 0 20px 0;
  width: 100%;
}

.plan-text-container {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.dashboard-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upgrade-button-container {
  display: flex;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
}
