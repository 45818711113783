.grid-container {
  display: flex;
  width: 800;
  padding: 0px;
  margin: 0 auto;
  /* border-top-left-radius: 5px; */
  margin-top: '1.5rem';
  gap: 8px;
}

.plan-details-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.plan-details-link h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;

  color: #afafaf;
}

.headContent #modal-modal-title {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #318236;
  text-align: center;
  margin-bottom: 30px;
}
.unvisibel {
  opacity: 0;
}
.headContentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 6px;
}
