.vmcr-row-header {
  color: var(--Secondary, #525256);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.vmcr-row-text {
  color: var(--text-11, gray);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.vmcr-business-info-container {
  color: #525256;
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-top: 16px;
}

.vmcr-business-info-row {
  display: flex;
  gap: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.vmcr-description-title {
  font-size: 20px;
  color: #525256;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.vmcr-step-title-container {
  display: flex;
  justify-content: left;
  margin-bottom: 12px;
  margin-top: 4px;
  color: gray;
}

.vmcr-step-title-container div {
  color: gray !important;
  /* Regular 16-24 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.vmcr-description-subheader {
  color: gray;
  margin-bottom: 5px;
  font-size: 16px;
}

.vmcr-confirmation-container {
  display: flex;
  justify-content: center;
  padding: 24px;
}

.vmcr-confirmation-container div {
  text-align: center;
}

.vmcr-confirmation-container .my-shipments-header {
  font-weight: 700;
  font-size: 20px;
  color: #525256;
}
