.value-fees-dc {
  font-size: 16px;
}

.subtitle-fees-dc {
  font-weight: 500;
  font-size: 20px;
}

.blur {
  filter: blur(5px); /* Apply the blur effect */
}

.top-text-dc {
  font-size: 20px;
  display: flex;
  color: #525256 !important;
  justify-content: space-between;
}

.top-text-dc > div > div {
  border-top: 1px solid lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dc-column-header {
  font-weight: 700;
}

.fee-name-container,
.calculation-text-container,
.duties-fees-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.calculator-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
}
.free-tipe {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 600px) {
  .top-text-dc {
    font-size: 12px;
    line-height: 1.8;
  }

  .fee-name-container {
    flex-grow: 1;
  }

  .top-text-dc > div > div {
    min-width: 100%;
  }
}
