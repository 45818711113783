.headContent .modal-title {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: #318236;
  text-align: center;
  margin-bottom: 0;
}

.headContent .monthText {
  color: #d9d9d9;
  font-size: 14px;
  font-weight: 700;
}

.btnPlan {
  border-radius: 20px !important;
  background: #0d47a1 !important;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

plan-details-link h6 {
  text-decoration-line: none;
}

.order-line {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  color: #000000;
}

.price {
  margin-top: 0.3rem;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 12px;
  color: #000000;
}

.monthly-prices {
  display: flex;
  flex-direction: row;
}

.advertised-price {
  text-decoration: line-through;
  padding-right: 12px;
}
