.checkbox-container-address {
  display: flex;
  justify-content: center;
}

.checkbox-container-address {
  align-items: center;
}

.states-dropdown-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.card-container > .next-btn {
  display: flex;
  justify-content: center;
  margin-bottom: -10px;
  margin-top: -10px;
}

.business-fields-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 32px;
  gap: 10px;
}

.business-form-field-container {
  margin-bottom: 4px;
  margin-top: 16px;
}

.business-form-field-label {
  margin-bottom: 7px;
  color: #525256;
  font-weight: 500;
}

.ein-structure-section {
  display: flex;
  gap: 32px;
  width: 100%;
}

.ein-structure-section > div {
  width: 50%;
}

.business-info-header .MuiTypography-root {
  color: #525256 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}
