.vmcr-name-variation-row {
  display: flex;
  margin-top: 12px;
  gap: 4px;
  margin-bottom: 12px;
}

.vmcr-description-title-container {
  display: flex;
  justify-content: space-between;
}

.vmcr-add-button-text {
  text-transform: none !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 8px 20px !important;
  display: flex !important;
}
