.customClearnanceWrapper {
  padding-bottom: 40px;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-height: 420px;
}

.mainWrapper .cargo-value-text-field,
.mainWrapper .transportation-buttons {
  margin: 25px 0;
  width: 100%;
  max-width: 400px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}
.mainWrapper .cargo-value-text-field .MuiButton-root {
  border: 3px solid #afafaf;
  border-radius: 22px;
  font-weight: 400;
  font-size: 18px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  padding: 0 10px;
  text-transform: capitalize;
}
.mainWrapper .cargo-value-text-field {
  display: flex;
  gap: 60px;
}

.mainWrapper .transportation-buttons button {
  width: 174px;
  min-width: 174px;
  height: 174px;
}
.mainWrapper .transportation-buttons {
  display: flex;
  gap: 42px;
}

.importMainWrapper {
  flex: 1;
  display: flex;
  gap: 60px;
  width: 100%;
  margin-top: 24px;
  justify-content: center;
}
.customSelect {
  flex: 1;
  max-width: 320px;
}
.customSelect .MuiAutocomplete-hasPopupIcon {
  width: 100%;
}
.customSelect fieldset {
  border: 3px solid #afafaf;
  border-radius: 22px;
}
.MuiDialog-paper {
  max-height: calc(100vh - 320px);
}
.completeSection {
  justify-content: center;
}
.dashboardCommon {
  display: none;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 80px;
}
.dashboardCommon .unlading-logo-dashboard {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
}

.dashboardCommon .unlading-logo-dashboard img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.cargo-value-text-field .MuiFormControl-root {
  width: 100%;
}

.customs-clearance-buttons {
  display: flex;
  justify-content: center;
}

.customs-clearance-buttons Button {
  font-weight: bold;
  font-size: 20px;
  height: 50px;
  width: 130px;
  border-radius: 30px;
  text-transform: none;
}

@media only screen and (max-width: 768px) {
  .dashboardCommon {
    display: flex;
  }
}
.custom-content-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.custom-content-body .yes-no-buttons Button,
.custom-content-body .hts-next Button {
  margin: 0 0 24px;
  border-radius: 25px;
  line-height: 38px;
  width: 200px;
  height: auto;
  font-size: 14px;
  text-transform: uppercase;
}

.custom-content-body .cargo-value-text-field .MuiAutocomplete-root {
  margin-top: 10px;
}

.custom-content-body .cargo-value-text-field {
  flex: 1;
  overflow: auto;
}

.upload-commercial-docs label {
  font-weight: bold;
  font-size: 16px;
  height: 40px;
  width: 200px;
  display: flex;
  justify-content: left;
  text-transform: none;
  border-radius: 30px;
  padding-right: 10px;
  padding-right: 10px;
}

.upload-commercial-docs label span {
  margin-left: 10px;
}
