.profile-header-container {
  display: flex;
  justify-content: space-between;
}

.settings-profile-main-content {
  display: flex;
  justify-content: left;
  gap: 64px;
  margin-top: 12px;
}

@media (max-width: 600px) {
  .settings-profile-main-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
