.MuiStepLabel-root .MuiStepLabel-label {
  font-size: 16px !important;
  white-space: normal !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  hyphens: auto !important;
}

.mobile-stepper .step-indicator {
  display: flex;
  align-items: center;
  padding: 24px 12px;
  justify-content: center;
  flex-direction: column;
}

.step-indicator-circle {
  background-color: #388e3c;
  color: white;
  border-radius: 50%;
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.step-indicator-text {
  margin-left: 10px;
  font-weight: 600;
}

@media (min-width: 1280px) {
  .MuiStepLabel-root .MuiStepLabel-label {
    font-size: 16px !important;
  }
}

@media (max-width: 960px) {
  .MuiStepLabel-root .MuiStepLabel-label {
    font-size: 12px !important;
  }
}

@media (max-width: 600px) {
  .MuiStepLabel-root .MuiStepLabel-label {
    font-size: 8px !important;
  }
  .step-indicator-circle {
    font-size: 24px;
  }
}
