.classifications-table .importal-table-header-row-cell {
  text-align: center;
}

.classifications-table .sorting {
  transform: translateX(9px);
}

.classifications-table .filtering {
  transform: translateX(20px);
}

/* Add this class to handle the case where both sorting and filtering classes are present */

.classifications-table .sorting.filtering {
  transform: translateX(26px);
}
