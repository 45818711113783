.isf-buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.isf-buttons Button {
  font-weight: bold;
  font-size: 18px;
  height: 40px;
  width: 175px;
  border-radius: 30px;
  text-transform: none;
  margin-left: 5px;
  margin-right: 5px;
}
