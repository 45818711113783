.subscription-wrapper h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #508b46;
  text-align: left;
  padding: 24px 29px;
}

.subscription-wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 24px;
}
.footer-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 29px;
}
.footer-bottom .btn-subscription {
  background: #0d47a1;
  border-radius: 30px;
  text-transform: capitalize;
}
