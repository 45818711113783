.optional {
  font-weight: bold;
}

.optional-descriptive-text {
  font-size: 16px;
  color: #525256;
  display: flex;
  justify-content: left;
  margin-top: 16px;
}
