.entry-info-container {
  display: flex;
  flex-wrap: wrap;
}

.entry-row-header {
  color: var(--Secondary, #525256);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.entry-row-text {
  color: var(--text-11, gray);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  min-width: 100px;
}

.entry-business-info-container {
  color: #525256;
  margin-top: 16px;
}

.cmq-text-header {
  font-weight: 600;
  font-size: 18px;
  color: #525256;
  margin-top: 16px;
}

.entry-business-info-row {
  display: flex;
  gap: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.cmq-button-container {
  display: flex;
  align-items: center;
}

.cmq-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 120px;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 12px 10px !important;
  gap: 8px !important;
}

.cmq-button-green:disabled {
  background-color: #eeeeee !important;
}

.cmq-button-container .cmq-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.cmq-button-content {
  display: flex;
}

.cmq-button-icon-right {
  border-left: 1px solid white;
}

.cmq-button-group {
  display: flex;
  justify-content: center;
}

.raw-cmq-container {
  display: flex;
  padding: 2%;
  color: #525256;
}

.cmq-line-item-container {
  padding-left: 2%;
  padding-right: 2%;
}

.entry-card-header-container {
  display: flex;
  justify-content: space-between;
}

.no-results-container {
  padding: 2%;
  display: flex;
  justify-content: center;
  color: #525256;
}

.shipment-history-date-time {
  width: 8%;
  display: flex;
}

.entry-history-entry-updates {
  width: 10%;
}

.entry-history-cmq-updates {
  width: 15%;
}

.shipment-history-summary {
  width: 60%;
}

.cmq-line-header {
  font-size: 16px;
  color: #525256;
  display: flex;
  padding: 10px;
}

.cmq-line-header > div {
  display: flex;
  gap: 12px;
}

.cmq-line-header-prefix {
  font-weight: 600;
  display: flex;
}

.entry-info-container .MuiInputBase-root {
  border-radius: 4px !important;
  background: white !important;
  font-size: 16px !important;
  color: var(--text-11, gray);
  padding: 0 !important;
}

.entry-info-container .MuiAutocomplete-root {
  border-radius: 4px !important;
  background: white !important;
  font-size: 16px !important;
  color: var(--text-11, gray);
  padding: 0 !important;
  border: none;
}

.shipment-row-text-subtitle {
  font-size: 10px;
}

.shipment-summary-header-row-container > div {
  display: flex;
}

.shipment-summary-header-main-info .MuiInputBase-root {
  border-radius: 4px !important;
  background: white !important;
  font-size: 16px !important;
  color: var(--text-11, gray);
  padding: 0 !important;
  margin-left: 5px;
}

.shipment-summary-row-header {
  color: var(--Secondary, #525256);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
}

.shipment-summary-row-text {
  color: var(--text-11, gray);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

.shipment-summary-header-main-info {
  display: flex;
  gap: 4px;
  align-items: center;
}

.shipment-summary-header-main-info > div {
  display: flex;
}

.shipment-breakdown-container-row {
  display: flex;
  border-bottom: 1px solid #d1d1d1;
  padding: 4px;
  padding-left: 0px;
  gap: 8px;
}

.shipment-page-breakdown-container-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  padding: 4px;
  padding-left: 0px;
  gap: 8px;
  color: #525256;
}

.shipment-page-breakdown-container-header div {
  font-weight: bold;
}

.cmq-text-subheader {
  color: var(--text-11, gray);
  display: flex;
  gap: 16px;
}

.shipment-history-type {
  width: 10%;
}

.shipment-history-change-count {
  width: 8%;
}

.vizion-button-container {
  display: flex;
  align-items: center;
}

.vizion-button-green {
  text-transform: none !important;
  color: grey !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
}

.vizion-button-green:disabled {
  background-color: #eeeeee !important;
}

.vizion-button-container .vizion-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}
