.pga-description-subheader {
  color: gray;
  margin-top: 8px;
  margin-bottom: 5px;
  font-size: 16px;
}

.pga-description-title {
  font-size: 20px;
  color: #525256;
  font-weight: 700;
  line-height: 28px;
  margin-top: 32px;
}

.pga-questions-container {
  margin-top: 64px;
  color: gray;
  margin-top: 64px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
}
