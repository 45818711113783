.btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px 20px;
}

.btn-group .btn-custom {
  background: #0d47a1;
  border-radius: 30px;
}

.change-pwd-wrapper h2 {
  display: flex;
  justify-content: flex-start;
}
.change-pwd-wrapper .card-content {
  margin-bottom: 24px;
}
.change-pwd-wrapper .title-text {
  margin-bottom: 24px;
}
