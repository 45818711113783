.custom-table-wrapper .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
  background: #f6f7f9;
}
.custom-table-wrapper .MuiPaper-root .MuiTable-root .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body img {
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.custom-table-wrapper
  .MuiPaper-root
  .MuiTable-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-body:nth-child(odd) {
  border: none;
}

.custom-header h5,
.custom-plans h5 {
  font-size: 20px;
}
.custom-plans .btn-value {
  background: #0d47a1;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  padding: 5px 20px;
  margin-top: -15px;
  display: inline-flex;
  justify-content: center;
}
.custom-plans .btn-pro {
  background: #f1d97c;
  color: #2f4d4d;
  border: solid 2px #2f4d4d;
  padding: 5px 20px;
  border-radius: 6px;
}
.custom-plans .btn-pro:hover {
  background: #388e3c;
  color: #fff;
}
.custom-plans .btn-plan {
  padding: 10px 20px;
  background: #fff;
  border-radius: 4px;
  color: #2f4d4d;
  font-size: 14px;
  line-height: 16px;
  border: solid 2px transparent;
}
.custom-plans .btn-plan:hover {
  background: #f1d97c;
  border: solid 2px #2f4d4d;
  color: #2f4d4d;
}
.card-main-wrapper.active {
  border-color: #388e3c;
}
.card-main-wrapper {
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 0px 12px 16px 0px rgba(76, 103, 100, 0.05);
}
.card-main-wrapper .card-content .img-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-icon svg {
  border-radius: 5px;
}

.card-main-wrapper .card-content .card-element .asterick {
  color: grey;
}

.card-main-wrapper .card-content .card-element p {
  margin: 0;
  display: flex;
  align-items: center;
}
.card-main-wrapper .card-content .card-element p:last-child {
  color: grey;
}
.card-main-wrapper .card-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin: 0 !important;
}
.change-pwd-wrapper h3 {
  margin: 0;
}
.change-pwd-wrapper button svg {
  margin-right: 0;
}
.radio-btn button {
  white-space: nowrap;
}
.radio-btn {
  display: flex;
  align-items: center;
}
