.contact-us-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 20px;
}

.contact-us-descriptive-text {
  text-align: center;
  font-size: 10px;
}

.contact-button-main {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.contact-button-main:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.contact-button:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.contact-cta-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin: 20px 0;
}

.contact-cta-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .my-shipments-header {
    font-size: 18px !important;
  }

  .contact-cta-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .contact-cta-item {
    display: flex;
    flex-direction: column;
  }

  .contact-button {
    width: 100%;
  }
}
