.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links-section {
  padding: 24px 0px;
  position: relative;
}

.links-title {
  font-weight: 700;
}
