.broker-shipments-table-container .MuiTableCell-root {
  padding: 4px !important;
  /* border-bottom: 1px solid grey!important; */
}

.status-chip-container .MuiChip-label {
  padding-right: 4px;
  padding-left: 4px;
}

.history-container {
  margin: 16px;
}

.shipment-history-date-time {
  display: flex;
}

.shipment-history-entry-updates {
  width: 10%;
}

.shipment-history-cmq-updates {
  width: 15%;
}

.shipment-history-user {
  width: 10%;
}

.shipment-breakdown-container-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  padding: 4px;
  padding-left: 0px;
  gap: 8px;
  margin-top: 4px;
  margin-left: 1%;
  margin-right: 1%;
}

.shipment-breakdown-container-header div {
  font-weight: bold;
  color: #525256;
  font-size: 14px;
}

.chip-wrapper {
  display: flex;
  gap: 2px;
}
