.action-icons {
  position: sticky;
  right: 0;
  background-color: #fdfdfd;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  text-align: right !important;
  padding: 8px;
  white-space: nowrap;
}
