.not-found-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.not-found-card-content .not-found-header-question {
  margin-bottom: 8px;
}

.not-found-header-question .MuiTypography-root {
  color: #525256 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important;
}

.not-found-card-container b {
  margin-left: 3px;
}

.not-found-card-content {
  padding: 64px;
}

.not-found-logo-container {
  margin-top: -20px;
  display: flex;
  justify-content: center;
  margin-bottom: -12px;
}

.back-to-dashboard {
  font-weight: bold;
  color: #525256;
  text-decoration: none;
}
