@media only screen and (max-width: 768px) {
  .MuiCardContent-root {
    padding: 16px !important;
  }
  .next-button-container .back-button-text {
    margin-right: 0px;
  }

  .universal-subheader {
    margin-bottom: 0px;
  }
}
