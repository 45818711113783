.sign-up-wrapper {
  display: flex;
  justify-content: row;
}

.sign-up-and-features-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.left-part {
  width: 50%;
}

.features-container {
  width: 50%;
}

.features-container {
  border-left: 1px solid lightgray;
  margin-left: 32px;
  padding-left: 32px;
}

.name-row,
.email-password-row {
  display: flex;
  gap: 16px;
}

.business-phone-container {
  padding-left: 20%;
  padding-right: 20%;
}

.signup-form-field-container {
  margin-bottom: 20px;
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.features-container .feature-row {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  animation-delay: var(--animation-delay);
  font-weight: 600;
}

.features-container .feature-row:nth-child(1) {
  --animation-delay: 0.1s;
}

.features-container .feature-row:nth-child(2) {
  --animation-delay: 0.2s;
}

.features-container .feature-row:nth-child(3) {
  --animation-delay: 0.3s;
}

.features-container .feature-row:nth-child(4) {
  --animation-delay: 0.4s;
}

.features-container .feature-row:nth-child(5) {
  --animation-delay: 0.5s;
}

.features-container .feature-row:nth-child(6) {
  --animation-delay: 0.6s;
}

.features-container .feature-row:nth-child(7) {
  --animation-delay: 0.7s;
}

.features-container .feature-row:nth-child(8) {
  --animation-delay: 0.8s;
}

.key-features-header {
  font-size: 20px;
  color: #525256;
  font-weight: 700;
  padding-bottom: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.feature-row {
  display: flex;
  padding: 4px;
}

.feature-row-text {
  color: grey;
  font-weight: 600;
  width: 250px;
  display: flex;
  align-items: center;
}

.feature-row-check {
  display: flex;
  align-items: center;
}

.features-row-container {
  display: flex;
  justify-content: center;
}

.testimonials-card {
  background: radial-gradient(circle, #ffffff 10%, #f0f7f0 75%, #f0f7f0 100%) !important;
  box-shadow: none !important;
  font-weight: 700;
  padding: 24px !important;
}

.testimonials-card .MuiTypography-root {
  font-weight: 700 !important;
  color: #525256 !important;
  line-height: 1.7rem;
}

.quote {
  font-weight: 700 !important;
  color: #525256 !important;
  line-height: 1.7rem;
  font-size: 12px;
  margin-top: 12px;
}

.signup-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.terms-conditions-container {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.terms-conditions-container a {
  color: white;
}

@media (max-width: 1120px) {
  .signup-header-text {
    display: flex;
    justify-content: center;
  }

  .left-part {
    width: 100%;
  }

  .features-container {
    display: none;
  }
}

@media (max-width: 480px) {
  .signup-header-text {
    text-align: center;
  }

  .signup-header-text .MuiTypography-root {
    font-size: 24px !important;
  }
}
