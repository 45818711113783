:root {
  --left-menu-width-collapsed: 88px;
  --left-menu-width-expanded: 276px;
}

.left-menu-container {
  display: flex;
  height: 100%;
}

.left-menu {
  width: var(--left-menu-width-expanded);
  position: fixed;
  background-color: #171717;
  color: white;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  height: 100vh;
  transition: width 0.4s ease;
}

.left-menu.collapsed {
  width: var(--left-menu-width-collapsed);
}

.importal-logo-dashboard {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: -14px;
  border-bottom: 1px solid var(--special-bg-3, rgba(255, 255, 255, 0.08));
  align-items: center;
}

.importal-logo-dashboard-collapsed {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 17px;
  padding-bottom: 37px;
  border-bottom: 1px solid var(--special-bg-3, rgba(255, 255, 255, 0.08));
}

@media (max-width: 768px) {
  .left-menu-container.visible ~ .dashboard-content,
  .left-menu-container.visible ~ .broker-dashboard-content {
    margin-left: var(--left-menu-width-collapsed);
    padding: 24px 16px;
  }

  .left-menu-container.hidden ~ .dashboard-content,
  .left-menu-container.hidden ~ .broker-dashboard-content {
    margin-left: 0px;
    padding-left: 24px;
  }

  .left-menu-container.hidden {
    display: none;
  }

  .left-menu-container.visible {
    display: block;
  }
}
