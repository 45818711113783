.product-table-row-dense .importal-table-cell {
  padding: 0;
}

.product-table-row-dense .importal-table-header-row-cell {
  padding: 0;
}

.product-expanded-header {
  font-weight: 700;
  font-size: 20px;
  color: #525256;
}

.product-expanded-subheader {
  color: gray;
  margin-bottom: 5px;
  font-size: 14px;
}

.product-expanded-section-header {
  font-weight: 700;
  font-size: 16px;
  color: hsl(240, 2%, 33%);
  margin-top: 20px;
}
