.call-to-action-snackbar-container {
  background-color: #77a8f6 !important;
  color: white !important;
  padding: 24px;
  border-radius: 16px;
  font-size: 16px;
  max-width: 750px;
}

.call-to-action-snackbar-container h3 {
  margin: 0;
  font-size: 18px;
}

.upgrade-link {
  text-transform: none;
  color: white;
  font-weight: bold;
  text-align: right;
  font-size: 16px;
}

.upgrade-container {
  display: flex;
  justify-content: right;
  font-size: 16px;
}
