.vmcr-card {
  background: #ffffff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  align-items: flex-start;
  box-shadow:
    0px 8px 12px 0px rgba(76, 103, 100, 0.03),
    0px -8px 12px 0px rgba(76, 103, 100, 0.03),
    8px 0px 12px 0px rgba(76, 103, 100, 0.03),
    -8px 0px 12px 0px rgba(76, 103, 100, 0.03) !important;

  justify-content: space-between;
  flex: 1;
  margin-top: 8px;
}

.vmcr-card .planName {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #525256;
}

.vmcr-card .planPrice {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  color: #525256;
}

.vmcr-card .monthplan {
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #d9d9d9;
}

.vmcr-card .btn {
  background: #0d47a1;
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  display: inline-flex;
  width: auto;
  color: #ffffff;
  margin-top: 16px;
  text-transform: none;
}

.vmcr-card:first-child {
  border-radius: 11px 0 0 11px;
}

.vmcr-card:last-child {
  border-radius: 0 11px 11px 0;
}

.vmcr-card .plan-list-item-container {
  display: flex;
  gap: 4px;
  font-size: 13px;
}

.vmcr-pricing-button-container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
}

.vmcr-pricing-button-container .vmcr-pricing-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 12px !important;
  font-style: normal !important;
  width: 80px;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.vmcr-card .plan-list-item-container {
  display: flex;
  align-items: center;
}

.vmcr-card .plan-list-item-container b {
  margin-right: 2px;
}

.check-container {
  display: flex;
  align-items: center;
}

.current-plan-text {
  font-size: 14px;
  color: grey;
  font-weight: 700;
  padding-bottom: 6px;
}
