.review-header-row {
  font-size: 18px;
  color: gray;
  font-weight: 600;
  line-height: 28px;
  display: flex;
  justify-content: left;
  margin-top: 12px;
}

.review-header-row-text {
  color: #388e3c;
}
