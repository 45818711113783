.fade-in-text {
  font-size: 60px;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.calculator-container {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 2px solid lightgrey;
  font-size: 20px;
  text-align: center;
}

.top-text {
  font-size: 20px;
  display: flex;
}

.calculator-container-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.calculation-text-container {
  text-align: right;
}

.calculation-text-container div {
  display: flex;
  justify-content: center;
}

.duties-fees-container div {
  display: flex;
  justify-content: right;
  padding-right: 12px;
}

.fee-name-container div {
  padding-left: 12px;
}

.bottom-text {
  display: flex;
  justify-content: center;
  font-size: 30px;
  border-top: 1px solid lightgray;
  margin-bottom: 6px;
  padding-top: 6px;
  color: #525256;
}

.total-owed {
  font-weight: 700;
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.fee-name-container {
  text-align: left !important;
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.amount-header {
  font-weight: normal !important;
}

.top-text > div > div {
  border-top: 1px solid lightgray;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 215px;
}

.value-fees {
  font-size: 18px;
}

.animate.pop {
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.delay-1 {
  animation-delay: 1s;
}

.delay-2 {
  animation-delay: 2s;
}

.total-number {
  margin-left: 4px;
}

.delay-3 {
  animation-delay: 3s;
}

.delay-4 {
  animation-delay: 4s;
}

.delay-5 {
  animation-delay: 5s;
}

.calculate-another Button {
  border-radius: 30px;
  text-transform: none;
}

.calculate-another {
  display: flex;
  justify-content: center;
}

.summary-header {
  margin-bottom: 0px;
  margin-top: 0px;
}

.breakdown-container-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  padding: 16px 0px;
  padding-left: 0px;
  gap: 8px;
  font-weight: bold;
}

.breakdown-container-header div:nth-child(1),
.breakdown-container-row div:nth-child(1),
.breakdown-container-row-end div:nth-child(1) {
  flex: 2;
}

.breakdown-container-header div:nth-child(2),
.breakdown-container-row div:nth-child(2),
.breakdown-container-row-end div:nth-child(2),
.breakdown-container-header div:nth-child(3),
.breakdown-container-row div:nth-child(3),
.breakdown-container-row-end div:nth-child(3) {
  flex: 1;
}

/* pga rows styling */

.breakdown-container-pga-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d1d1d1;
  padding: 16px 0;
  gap: 8px;
  font-weight: bold;
}

.breakdown-container-pga-header div:nth-child(1),
.breakdown-container-pga-row div:nth-child(1) {
  width: 200px;
}

.breakdown-container-pga-header div:nth-child(2),
.breakdown-container-pga-row div:nth-child(2) {
  width: 200px;
}

.breakdown-container-pga-header div:nth-child(3),
.breakdown-container-pga-row div:nth-child(3) {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  width: 600px;
}

.breakdown-container-pga-header div:nth-child(4),
.breakdown-container-pga-row div:nth-child(4) {
  width: 150px;
}

.breakdown-container-pga-row {
  display: flex;
  width: 100%;
  padding: 8px 0;
  gap: 8px;
  border-bottom: 1px solid #d1d1d1;
}

.breakdown-container-row {
  display: flex;
  width: 100%;
  padding: 8px 0;
  gap: 8px;
  border-bottom: 1px solid #d1d1d1;
}

.breakdown-container-row-end {
  display: flex;
  width: 100%;
  padding: 8px 0;
  gap: 8px;
  color: gray;
}

.agency-name {
  width: 34%;
}

.compliance-summary-agency-name {
  width: 15%;
}

.pga-flag {
  width: 8%;
  display: flex;
}

.compliance-summary-pga-flag {
  width: 10%;
  display: flex;
}

.impact-on-duty {
  width: 25%;
}

.exclusion-name {
  width: 30%;
}

.agency-description {
  width: 48%;
  display: flex;
}

.compliance-summary-agency-description {
  width: 62%;
  display: flex;
}

.tooltip-summary-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
}

.variable-text {
  margin-right: 5px;
}

.hdfs-fee-type-text,
.hdfs-duty-rate-text {
  width: 25%;
}

.btd-button {
  display: flex;
  justify-content: center;
}

.btd-button Button {
  font-weight: bold;
  font-size: 15px;
  height: 40px;
  width: 160px;
  border-radius: 30px;
  text-transform: none;
  margin-bottom: 30px;
}

.compliance-summary-subheader {
  margin-top: 8px;
}

.summary-card-container {
  margin-top: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.1) !important;
}

.pga_info-icon {
  margin-left: auto;
  font-size: 14px;
}

.pga-info-icon:hover {
  cursor: pointer;
}

.duty-calculation-rate {
  width: 15%;
  display: flex;
}

.duty-calculation-amount {
  width: 48%;
  display: flex;
}

.duty-calculation-type {
  width: 30%;
}

.next-button-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.my-shipments-subheader {
  display: flex;
  gap: 40px;
  flex-direction: row;
}

@media (max-width: 768px) {
  .my-shipments-subheader {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  .value-fees {
    font-size: 14px;
  }

  .breakdown-container-row {
    padding: 16px 0px;
    font-size: 14px;
  }

  .next-button-container {
    gap: 0px;
    margin-top: 20px;
  }

  .next-button-container.compliance-summary {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }

  .back-button-text,
  .next-button-green {
    width: 100%;
    font-size: 14px !important;
    padding: 10px 14px;
  }
}
