.vmcr-review-title {
  font-size: 18px;
  color: #525256;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.vmcr-enter-payment-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.vmcr-order-line {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-style: normal;
  color: grey;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
}
