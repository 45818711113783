.step-component-bi-container {
  padding: 16px;
}

.customs-onboarding-confirmation {
  margin-top: 64px;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 64px;
  color: #525256;
  text-align: center;
}

.confirmation-title {
  font-size: 18px;
  font-weight: 600;
}

.confirmation-subtitle {
  font-size: 16px;
  color: gray;
}
