.card-custom .buy-btn {
  border-radius: 30px;
  background: #0d47a1;
  text-transform: capitalize;
}

.plan-next-steps {
  margin-top: 8px !important;
  color: slategrey !important;
}
