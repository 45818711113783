.upload-hint-container:hover .upload-hint-row:hover {
  display: table-row; /* Show the row on hover */
}

.upload-hint-container {
  font-weight: 600;
  color: #525256;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
}
