.add-user-row {
  display: flex;
  gap: 16px;
}

.user-row .MuiInputBase-root {
  border-radius: 4px !important;
  background: white !important;
  font-size: 14px !important;
}
