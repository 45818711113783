.add-card-buttons {
  display: flex;
  justify-content: right;
}

.add-card-header {
  display: flex;
  justify-content: space-between;
}

.add-card-buttons Button {
  font-weight: bold;
  font-size: 14px;
  border-radius: 30px;
  text-transform: none;
}

.add-card-buttons .btn-custom {
  background: #0d47a1;
  border-radius: 30px;
}

.no-cards-on-file {
  display: flex;
  justify-content: center;
}

.card-modal-container .MuiDialogTitle-root {
  font-size: 24px;
  color: var(--secondary, #525256);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  margin-bottom: 32px;
}

.billing-header-container {
  display: flex;
  justify-content: space-between;
}

.add-card-container {
  margin-top: 0px !important;
}
