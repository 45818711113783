.parsed-cmq-container {
  box-shadow:
    0px 8px 12px 0px rgba(76, 103, 100, 0.03),
    0px -8px 12px 0px rgba(76, 103, 100, 0.03),
    8px 0px 12px 0px rgba(76, 103, 100, 0.03),
    -8px 0px 12px 0px rgba(76, 103, 100, 0.03);
  border-radius: 16px;
  padding: 10px;
  margin-top: 8px;
  color: #525256;
}

.parsed-cmq-container > div {
  border-bottom: 1px solid lightgray;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.parsed-cmq-container > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.cmq-line-item-container > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.parsed-cmq-container .wr1-line {
  border-bottom: none;
  padding-bottom: 0;
}

.wr5-line-table-header-row {
  border-bottom: 1px solid lightgray;
}

.wr5-line-table-row {
  border-bottom: 1px solid lightgray;
}

.wr5-line-table {
  text-align: left;
  border-collapse: collapse;
  padding-left: 2%;
  padding-right: 2%;
  width: 100%;
}

.wr5-line-table tbody .wr5-line-table-row:last-child {
  border-bottom: none;
}

.wr5-line-table-header-row > th {
  padding-right: 12px;
}
.wr5-line-table-header-row > th:last-child {
  padding-right: 0px;
}

.wr5-line-table-row > td {
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.wr5-line-table-row > td:last-child {
  padding-right: 0px;
}
