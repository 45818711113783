.setting-wrapper h3 {
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  margin-bottom: 12px;
}
.card-custom {
  padding: 12px 15px;
  background-color: white;
}

.card-custom h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #508b46;
}
.card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.card-bottom-pay {
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
}

.card-bottom-pay button {
  background: #0d47a1;
}

.settings-card-container {
  margin-top: 5px;
  margin-bottom: 5px;
}

.card-bottom button {
  background: #0d47a1;
}
.card-bottom h6 {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #0d47a1;
}
.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.card-content .MuiFormControl-root {
  width: 100%;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 4px 0 16px;
}
.content-wrapper .card-content {
  display: flex;
  width: 100%;
  margin: 0;
}
.card-content .input-custom {
  flex: 1;
  display: flex;
  gap: 20px;
}
.subscription-plan {
  margin-bottom: 24px;
}
.subscription-plan .price-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subscription-plan h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 10px;
  color: #000000;
}
.subscription-plan .price-wrap p {
  margin: 0;
  flex: 3;
}
.subscription-plan .price-wrap {
  margin: 5px 0;
}
.subscription-plan .price-wrap .link-list {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 16px;
}
.subscription-plan .price-wrap .link-list h6 {
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  color: #508b46;
  cursor: pointer;
}
.date-wrap {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #adadad;
  margin-top: 24px;
}
.card-content .MuiFormControl-root {
  background-color: transparent;
}
