.dropdown-option-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.dropdown-option-container > div {
  flex-shrink: 0;
  margin-left: 3px;
  margin-right: 3px;
}

.option-name {
  min-width: 200px;
}

.option-parent {
  min-width: 250px;
}

.option-section {
  width: 20%;
}

.option-section-name {
  width: 80%;
}

.autocomplete-container {
  display: flex;
  width: 100%;
}

.loading-indicator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

.loading-indicator-container-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 35px;
}

@keyframes loadingBar {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.loading-text {
  display: inline-block;
  position: relative;
  color: black; /* Ensure the text color is visible */
}

.loading-text::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: loadingBar 2s linear infinite;
  color: black; /* Match this color to the text color */
  overflow: hidden;
}

.options-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  gap: 12px;
}

.dropdown-option-container {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .options-wrapper {
    flex-direction: column;
    gap: 0px;
  }

  .option-parent {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .option-code,
  .option-parent {
    margin-bottom: 12px;
  }
}
