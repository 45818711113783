.success-container {
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  height: 100vh;
}

.success-container div {
  text-align: center;
}
