.card-with-icon-message-and-cta-container {
  display: flex;
  width: 100%; /* Set the width as needed */
  border: 1px solid #ccc; /* Example border */
  padding: 24px; /* Adjust padding as needed */
}

.icon-container {
  flex: 0 0 auto; /* Do not grow or shrink */
  aspect-ratio: 1 / 1; /* Square aspect ratio */
  padding: 16px; /* Adjust padding as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-with-icon-message-and-cta-text-container {
  flex-grow: 1; /* Take up remaining space */
  padding: 0 16px; /* Adjust padding as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align header and message appropriately */
}

.header {
  font-size: 20px; /* Adjust font size as needed */
  font-weight: bold;
}

.message {
  font-size: 16px; /* Adjust font size as needed */
}

.close-button {
  align-self: flex-end;
}

.action-button {
  align-self: flex-end;
}
