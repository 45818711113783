.dashboard-content {
  width: 100%;
  padding: 24px;
  background: #f5f5f5;
  height: 100%;
  margin-left: 276px;
  margin-left: var(--left-menu-width-expanded);
  transition: margin-left 0.4s ease;
  overflow: auto;
}

.my-shipments-container {
  width: 100%;
}

.my-shipments-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  margin-bottom: 6px;
}

.my-shipments-header {
  font-weight: 700;
  font-size: 24px;
  color: #525256;
}

.plan-header {
  position: relative;
  top: 10px;
  font-weight: 700;
  font-size: 24px;
  color: #525256;
}

/* .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
} */

.call-to-action-container {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
}
.call-to-action-container > .MuiCard-root {
  flex: 1 1 calc(50% - 4px);
  max-width: calc(50% - 4px);
}

.my-shipments-list > div > div {
  padding: 0;
}

.table-container {
  width: 100%;
}

.table-cell {
  flex: 1;
}

.my-shipments-list-header {
  display: flex;
  justify-content: space-evenly;
}

.my-shipments-list-header > * {
  flex: 1;
}

.main-container {
  display: flex;
  margin-top: 6px;
}

.consultation-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}

.consultation-button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 12px 16px !important;
  align-items: center !important;
  gap: 12px !important;
}

.consultation-button-main {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 8px 20px !important;
  align-items: center !important;
  gap: 8px !important;
}

.consultation-button-main:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.consultation-button:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.import-services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.import-services-container div {
  text-align: center;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 4px;
}

.other-tools {
  width: 30%;
  margin-left: 5px;
}

.other-tools > div {
  margin-bottom: 6px;
}

.my-shipments-subheader {
  color: gray;
  margin-bottom: 5px;
  font-size: 14px;
}

.customEllipsis p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.customEllipsis {
  max-width: 200px;
}

.MuiAlert-message ul {
  margin-top: 0;
  margin-bottom: 0;
}

.MuiButton-startIcon {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.MuiButton-endIcon {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.call-to-action-container {
  justify-content: center;
}
.call-to-action-container > .MuiCard-root {
  max-width: 100%;
}

.compliance-buttons-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 17px;
  justify-content: center;
}

.mobile-alert-header {
  font-weight: 800;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .dashboard-content {
    width: 100%;
    left: 0;
    padding: 24px;
    overflow: auto;
  }
  .my-shipments-card {
    width: 100%;
  }
  .main-container {
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
  .other-tools {
    width: 100%;
    margin-left: 0;
  }
  .my-shipments-container {
    width: 100%;
    margin-left: 0;
  }
  .mobile-alert {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-content {
    width: 100%;
    left: 0;
    margin-left: var(--left-menu-width-collapsed);
    overflow: auto;
  }
  .my-shipments-card {
    width: 100%;
  }
  .main-container {
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }
  .other-tools {
    width: 100%;
    margin-left: 0;
  }
  .my-shipments-container {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .dashboard-content {
    width: 100%;
    left: 0;
    margin-left: var(--left-menu-width-collapsed);
    overflow: auto;
  }
}
