.poa-iframe-container {
  background-color: lightgrey;
  padding: 16px;
  border-radius: 4px;
}

.poa-iframe iframe {
  border-radius: 4px;
}

.photo-id-button-container .next-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 120px;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 8px 20px !important;
  align-items: center !important;
  gap: 8px !important;
}

.photo-id-button-container {
  display: flex;
}

.confirmation-container {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.photo-id-content-container {
  display: flex;
  justify-content: space-between;
}

.tab-label-container {
  display: flex;
}

.tab-label-text {
  display: flex;
  align-items: center;
  margin-right: 4px;
}
