.import-doc-button-container {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.import-doc-button-container .next-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 15px !important;
  font-style: normal !important;
  width: 140px;
  font-weight: 600 !important;
  line-height: 24px !important;
  display: flex !important;
  padding: 8px 20px !important;
  align-items: center !important;
  gap: 8px !important;
}

.import-doc-button-container .next-button-green:disabled {
  background-color: #949893 !important;
}

.import-doc-button-container .next-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}
