.business-subtitle-container {
  display: flex;
  justify-content: left;
  margin-bottom: 28px;
  margin-top: 4px;
  color: gray;
}

.business-subtitle-container div {
  color: gray !important;
  /* Regular 16-24 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.next-button-container {
  display: flex;
  justify-content: right;
}

.add-business-info-container {
  color: #525256;
  border-top: 1px solid #d1d1d1;
  padding-top: 8px;
  margin-top: 16px;
}

.add-business-info-row {
  display: flex;
  gap: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.add-business-description-title {
  font-size: 20px;
  color: #525256;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.customs-onboarded-title-container {
  color: gray;
  font-size: 16px;
  display: flex;
  justify-content: space-evenly;
  gap: 85px;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: -10px;
}

.customs-onboarded-title-container .title-question-header-container {
  display: flex;
  align-items: center;
}
