.create-shipment-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 12px 20px !important;
  gap: 8px !important;
}

.create-shipment-button-green:disabled {
  background-color: #eeeeee !important;
}

.create-shipment-button-container .create-shipment-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}
