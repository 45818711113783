.chat-card-container {
  display: flex;
  width: 100%;
}

.current-chat-container {
  width: 75%;
  margin-left: 3%;
  margin-right: 3%;
}

.chat-history-container {
  width: 25%;
  padding-right: 30px;
  border-right: 1px solid lightgray;
  margin-right: 10px;
}

.cal-content-container,
.user-content-container {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.current-chat-input {
  display: flex;
  gap: 6px;
  margin-top: 24px;
}

.cal-name,
.user-name {
  font-weight: 600;
  color: #525256;
}

.cal-logo-container img,
.user-logo-container img {
  border-radius: 100%;
}

.chat-history-header {
  display: flex;
  justify-content: center;
  color: #525256;
  font-weight: 600;
}

.cal-message,
.user-message {
  color: gray;
}

.this-week-header,
.last-week-header,
.earlier-header {
  margin-top: 6px;
  font-weight: 600;
  color: #525256;
  font-size: 14px;
}

.this-week-content,
.last-week-content,
.earlier-content {
  color: gray;
  font-size: 14px;
}

.no-chats {
  color: gray;
  font-size: 14px;
  padding: 6px 8px;
}

.chat-history-button {
  color: gray !important;
  text-transform: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.add-chat-container .MuiIconButton-root {
  background-color: #388e3c;
}

.add-chat-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 140px;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 12px 10px !important;
  gap: 8px !important;
}

.add-chat-button-green:disabled {
  background-color: #eeeeee !important;
}

.add-chat-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.cal-header {
  display: flex;
  justify-content: space-between;
}

.add-chat-container {
  display: flex;
  align-items: center;
}

.new-chat-logo-container {
  display: flex;
  justify-content: center;
}

.new-chat-logo-container img {
  border-radius: 100%;
  box-shadow: 0 3px 6px rgba(169, 169, 169, 0.5);
}

.new-chat-text-container {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #525256;
  margin-top: 12px;
}
