.table-container {
  margin-top: 0.5rem;
}

.product-library-header .MuiTypography-root {
  font-weight: 600;
}

.product-library-header {
  display: flex;
  justify-content: space-between;
}

.right-button-container {
  display: flex;
  align-items: center;
}

.right-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 180px;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 12px 10px !important;
  gap: 8px !important;
}

.right-button-green:disabled {
  background-color: #eeeeee !important;
}

.right-button-container .right-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.upload-products-modal-dialog-content > * {
  margin: 8px;
}

.products-list-upload-container {
  height: 100px;
  display: flex;
  margin-top: 12px;
  justify-content: center;
  align-items: center;
  color: grey;
  font-size: 14px;
}
