.shipments-table-container {
  margin-top: 0.5rem;
}

.businesses-main-page-header .MuiTypography-root {
  font-weight: 600;
}

.businesses-main-page-header {
  display: flex;
  justify-content: space-between;
}

.business-add-button-container {
  display: flex;
  align-items: center;
}

.business-add-button-container .business-add-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  width: 130px;
  font-weight: 600 !important;
  line-height: 24px !important;
  padding: 12px 10px !important;
  gap: 8px !important;
}

.business-add-button-container .business-add-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}
