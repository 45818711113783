.importing-card-container {
  padding-top: 0px !important;
}

.wayi-text-field {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.importing-header-question .MuiTypography-root {
  color: var(--gray-02, #388e3c);
  /* Header 22-32 */
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 145.455% */
}
