.step-title-container {
  display: flex;
  justify-content: left;
  margin-top: 16px;
  margin-bottom: 40px;
}

.step-title-container div {
  color: #525256 !important;
  /* Regular 16-24 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
