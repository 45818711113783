.classifications-table .importal-table-header-row-cell {
  text-align: center;
}

.classifications-table .sorting {
  transform: translateX(9px);
}

.classifications-table .filtering {
  transform: translateX(20px);
}
