.mode-of-transportation-button {
  flex-direction: column;
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.transportation-buttons-container {
  margin-top: 40px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.toggle-button-group-container {
  width: 750px !important;
  max-width: 100%;
}

@media (max-width: 600px) {
  .transportation-button {
    padding: 4px 6px;
    min-width: 40px;
  }

  .mode-of-transportation-button {
    gap: 4px;
    font-size: 14px;
  }

  .toggle-button-group-container {
    width: 250px !important;
    max-width: 100%;
  }
}
