.bond-yes-no-buttons .next-button-green {
  text-transform: none !important;
  background-color: #388e3c !important;
  color: #fdfdfd !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  width: 80px;
  line-height: 24px !important;
  display: flex !important;
  padding: 8px 20px !important;
  align-items: center !important;
  gap: 8px !important;
}

.bond-yes-no-buttons .next-button-green:hover {
  background-color: #c0dcc2 !important;
  color: #525256 !important;
}

.customs-form-field-label .MuiTypography-root {
  font-size: 20px !important;
  color: #525256;
  font-weight: 600;
}

.customs-form-field-label {
  display: flex;
  align-items: center;
}

.bond-yes-no-buttons {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: center;
}

.customs-question-container {
  margin-top: 16px;
  font-size: 16px;
}

.customs-question-container .title-question-subheader {
  color: gray;
}

.customs-question-container .title-question-header {
  color: #525256;
}

.customs-question-container .title-question-body {
  margin-top: 10px;
}

.pun-form-field-container {
  margin-bottom: 4px;
  margin-top: 16px;
  width: 50%;
}

.pun-email-container {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.customs-info-form-field-label {
  margin-bottom: 7px;
  color: #525256;
  font-weight: 500;
  display: flex;
}

.payment-cadences-header {
  font-size: 16px;
  font-weight: 600;
}

.payment-cadence-type-header {
  margin-top: 8px;
  font-weight: 600;
  margin-bottom: 2px;
}

.payment-cadences-container {
  margin: 6px;
}

.pun-brokers-text {
  height: 40px;
  display: flex;
  align-items: center;
}

.customs-info-title-question-header {
  font-weight: 600;
  color: #525256 !important;
  display: flex;
}

.business-form-field-label-content {
  display: flex;
}
