.shipment-general-info-row {
  display: flex;
  width: 100%;
  gap: 24px;
}

.shipment-general-info-row > .business-form-field-container {
  width: 25%;
}

.shipment-general-info-row > .documents-table-container {
  width: 100%;
}

.services-select-wrapper {
  display: flex;
  justify-content: row;
  max-height: '200px';
  margin: 16px 0px 4px 0px;
}
