.welcome-header-text .MuiTypography-root {
  color: white !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 28px !important; /* 116.667% */
}

.middle {
  text-align: center;
}

@media (max-width: 1120px) {
  .welcome-header-text {
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .welcome-header-text {
    text-align: center;
  }

  .welcome-header-text .MuiTypography-root {
    font-size: 24px !important;
  }
}
