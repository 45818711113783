.entry-business-info-row-section-header {
  font-size: 20px;
  font-weight: 700;
  margin-top: 12px;
}

.business-customs-checkbox-container {
  display: flex;
  font-size: 14px;
  color: gray;
  align-items: center;
}
